var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact" }, [
    _vm.submitted
      ? _c("div", { staticClass: "column has-text-centered" }, [_vm._m(0)])
      : _c("div", { staticClass: "column" }, [
          _c(
            "form",
            {
              ref: "frmContactMe",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Your Name")]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    ref: "inputName",
                    staticClass: "input inputName",
                    attrs: {
                      type: "text",
                      placeholder: "Mary J.",
                      minlength: "5",
                      maxlength: "30",
                      required: "",
                      disabled: _vm.loading
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Your Email")]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    ref: "inputEmail",
                    staticClass: "input inputEmail",
                    attrs: {
                      type: "email",
                      placeholder: "email@address.com",
                      required: "",
                      disabled: _vm.loading
                    }
                  })
                ])
              ]),
              _vm.subjects.length > 0
                ? _c("div", { staticClass: "field" }, [
                    _c("label", { staticClass: "label" }, [_vm._v("Subject")]),
                    _c("div", { staticClass: "control" }, [
                      _c("div", { staticClass: "select inputSubject" }, [
                        _c(
                          "select",
                          {
                            ref: "inputSubject",
                            attrs: { disabled: _vm.loading }
                          },
                          _vm._l(_vm.subjects, function(subject, index) {
                            return _c("option", {
                              key: index,
                              domProps: { textContent: _vm._s(subject) }
                            })
                          }),
                          0
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Message")]),
                _c("div", { staticClass: "control" }, [
                  _c("textarea", {
                    ref: "inputMessage",
                    staticClass: "textarea inputMessage",
                    attrs: {
                      placeholder: "Leave your message",
                      disabled: _vm.loading,
                      minlength: "5",
                      maxlength: "160",
                      required: ""
                    }
                  })
                ])
              ]),
              _c("div", { staticClass: "buttons is-right" }, [
                _vm.btnCancelText && _vm.btnCancelClick
                  ? _c(
                      "button",
                      {
                        staticClass: "button btnCancel",
                        attrs: { disabled: _vm.loading },
                        on: { click: _vm.btnCancelClick }
                      },
                      [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.btnCancelText) }
                        })
                      ]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "button is-primary btnSend",
                    class: { "is-loading": _vm.loading },
                    attrs: { disabled: _vm.loading },
                    on: { click: _vm.submit }
                  },
                  [_c("span", [_vm._v("Submit")])]
                )
              ])
            ]
          )
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "span",
        {
          staticClass: "icon",
          staticStyle: { "font-size": "40px", height: "100px" }
        },
        [_c("i", { staticClass: "fas fa-paper-plane" })]
      ),
      _c("p", [
        _vm._v("I appreciate that you've taken the time to write to me.")
      ]),
      _c("p", [_vm._v("I'll get back to you soon.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }