<template>
	<div class="contact">
		<div v-if="submitted" class="column has-text-centered">
			<div>
				<span class="icon" style="font-size: 40px; height: 100px">
					<i class="fas fa-paper-plane"></i>
				</span>
				<p>I appreciate that you've taken the time to write to me.</p>
				<p>I'll get back to you soon.</p>
			</div>
		</div>
		<div v-else class="column">
			<form @submit.prevent ref="frmContactMe">
				<div class="field">
					<label class="label">Your Name</label>
					<div class="control">
						<input
							class="input inputName"
							type="text"
							ref="inputName"
							placeholder="Mary J."
							minlength="5"
							maxlength="30"
							required
							:disabled="loading"
						/>
					</div>
				</div>

				<div class="field">
					<label class="label">Your Email</label>
					<div class="control">
						<input
							class="input inputEmail"
							type="email"
							placeholder="email@address.com"
							ref="inputEmail"
							required
							:disabled="loading"
						/>
					</div>
				</div>

				<div class="field" v-if="subjects.length > 0">
					<label class="label">Subject</label>
					<div class="control">
						<div class="select inputSubject">
							<select ref="inputSubject" :disabled="loading">
								<option
									v-for="(subject, index) in subjects"
									:key="index"
									v-text="subject"
								></option>
							</select>
						</div>
					</div>
				</div>

				<div class="field">
					<label class="label">Message</label>
					<div class="control">
						<textarea
							ref="inputMessage"
							class="textarea inputMessage"
							placeholder="Leave your message"
							:disabled="loading"
							minlength="5"
							maxlength="160"
							required
						></textarea>
					</div>
				</div>

				<div class="buttons is-right">
					<button
						v-if="btnCancelText && btnCancelClick"
						class="button btnCancel"
						@click="btnCancelClick"
						:disabled="loading"
					>
						<span v-text="btnCancelText"></span>
					</button>
					<button
						class="button is-primary btnSend"
						:class="{ 'is-loading': loading }"
						@click="submit"
						:disabled="loading"
					>
						<span>Submit</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
// import api from "@/../../../js/api.js"

export default {
	name: "ContactForm",
	props: {
		subjects: {
			type: Array,
			default: () => [],
		},
		btnCancelText: {
			type: String,
			default: "",
		},
		btnCancelClick: {
			type: Function,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
			submitted: false,
			apiClient: null,
		}
	},
	async created() {
		const api = await import("@/../../../js/api.js")
		this.apiClient = api.apiClient
	},
	methods: {
		async submit() {
			if (!this.$refs.frmContactMe.checkValidity()) return
			this.loading = true
			try {
				const data = {
					name: this.$refs.inputName.value.trim(),
					email: this.$refs.inputEmail.value.trim(),
					subject:
						this.subjects.length > 0
							? this.$refs.inputSubject.value.trim()
							: "Request",
					message: this.$refs.inputMessage.value.trim(),
				}
				const response = await this.apiClient.post(
					"contactUs",
					JSON.stringify(data),
				)
				if (response.status === 200) {
					let content = "Message submitted"
					this.submitted = true
					if (response.data.error)
						content =
							(response.data.error && response.data.error.message) ||
							JSON.stringify(response.data.error)
					setTimeout(() => {
						window.alert(content)
					}, 200)
				}
			} catch (error) {
				console.log("error", error)
				window.alert((error && error.message) || JSON.stringify(error))
			} finally {
				this.loading = false
			}
		},
	},
}
</script>

<style scoped>
.divSettings {
	margin-top: 10vh;
}

.buttons {
	margin-top: 60px;
}

form {
	width: 800px;
	max-width: 90vw;
	margin: auto;
}
</style>
